import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const Signage = ({ data, location }) => (
  <Layout>
    <SEO 
      pagetitle="サイネージ広告"
      pagedesc="サイネージ広告は各主要駅の主に改札周辺に設置してある動画広告です。複数面の同時展開となっていますので、同時に同じ動画が流れる様はインパクトあり視線を惹きつけます。
      近隣企業の告知や店舗誘引、キャンペーンの告知での利用に適しています。"
      pagepath={location.pathname}
      pageimg={data.signageimage2.childImageSharp.original.src}
      pageimgw={data.signageimage2.childImageSharp.original.width}
      pageimgh={data.signageimage2.childImageSharp.original.height}

    />
    <section className="area">
    <div className="container">
      <h2 className="bar">サイネージ広告</h2>
      <br />
      <figure>
        <GatsbyImage image={data.signageimage2.childImageSharp.gatsbyImageData} alt="" />
      </figure>
      <br /> 
      <p className="article">
      <span className="article-sub">サイネージ広告について</span><br />
      各主要の駅構内の改札周辺か通路に設置してある音声なしの動画広告です。輝度も高く屋内でも６０インチ以上の大きさのモニターなので
通行人で隠れてしまうことはない存在感で、細かい文字も鮮明に放映しますので、素材が同時に映る様は非常にインパクトあります。<br /><br />
早朝の５：００〜終電間際の２４：００まで繰り返し放映で、１ロール内で１５秒区切りで販売しています。７日単位と１ヶ月単位の商品展開が基本です。
他の広告媒体の様に、広告するにあたって取付撤去などの費用が掛からなく露出面数が多いので駅ポスターと同レベルの費用対効果が期待できます。
また駅構内の他媒体と組み合わせ方によっては、利用者が駅構内であらゆる箇所で常に接触している状態まで持っていく事も可能です。<br /><br />
デジタルサイネージの週に１回以上の接触者は、全体では約30%となっています。<br />
出典：Jeki首都圏移動者調査<br /><br />
また、広告到達率は約７０％。広告接触率・商品興味関心度・商品購入喚起度の項目では、
『見た（見たような気がする）』『興味を持った』または『とても買ってみたい』等と言う人の割合が約６割弱の数値に達しています。<br />
出典：交通広告共通指標策定調査<br /><br />
他で使っていた動画素材でも駅デジタルサイネージの仕様へ少し変えるだけで広告出稿出来たりしますので、
何か広告をと考えた際はデジタルサイネージ検討してみてください。
      </p>
    </div>
    </section>
    <section className="Advertising">
    <div className="container">
    <div className="block">
        <div className="ad-detail">
        <Link to={`/cat/signage_cat/`}>
        <button className="button-first">サイネージ広告一覧へ</button>
        </Link>
        </div>
        <div className="ad-detail">
        <Link to={`/form/`}>
        <button className="button-second">お問い合わせ</button>
        </Link>
        </div>
    </div>
    </div>
    </section>
  </Layout>
)
  
export const query = graphql`
  query {
    signageimage2: file(relativePath: {eq: "signageimage2.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        original {
          src
          height
          width
        }
      }
    }
  }
`

export default Signage